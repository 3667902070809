<template>
  <b-card title="Viết bài - Tin tức">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tiêu đề"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-first-name"
              placeholder=""
              v-model="postData.title"
              :class="{ 'is-invalid' : isInvalid(postData.title)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Mô tả/Tóm tắt"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-textarea
              id="post-summary"
              placeholder=""
              v-model="postData.summary"
              :class="{ 'is-invalid' : isInvalid(postData.summary)}"
            />
          </b-form-group>
        </b-col>
<!--        <b-col cols="12">-->
<!--          <b-form-group-->
<!--            label="Link sản phẩm"-->
<!--            label-cols-md="2"-->
<!--            label-for="h-first-name"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="h-product-link"-->
<!--              placeholder=""-->
<!--              v-model="postData.product_url"-->
<!--              :class="{ 'is-invalid' : isInvalid(postData.product_url)}"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <b-col cols="12">
          <b-form-group
            label="Tác giả"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-author"
              placeholder="Theo TT Nghiên cứu và phát triển Rau hoa quả"
              v-model="postData.author"
              :class="{ 'is-invalid' : isInvalid(postData.author)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Danh mục"
            label-cols-md="2"
            label-for="h-category"
          >
            <b-form-select
              :options="categoryOptions"
              class="mt-1"
              size="sm"
              v-model="postData.category_id"
              :class="{ 'is-invalid' : isInvalid(postData.category_id)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Nội dung"
            label-cols-md="12"
            label-for="h-category"
          >
            <quill-editor
              :height="1000"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              placeholder="Nhập nội dung tại đây"
              ref="quillEditor"
              v-model="postData.content"
              :class="{ 'is-invalid' : isInvalid(postData.content)}"
            >

            </quill-editor>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hình ảnh đại diện"
            label-cols-md="2"
            label-for="h-images"
          >
            <b-form-input
              id="product-avatar"
              v-model="postData.avatar"
              :class="{ 'is-invalid' : isInvalid(postData.avatar)}"
              placeholder=""
              rows="5"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="postData.avatar">
          <b-form-group
            label="Xem trước hình ảnh"
            label-cols-md="2"
            label-for="h-image-preview "
          >
            <b-img class="preview-image" :src="postData.avatar" v-viewer />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-button
          class="mr-1"
          type="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          Lưu thông tin
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
  import {
    BButton,
    BCard,
    BCol,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow
  } from 'bootstrap-vue'
  import Ripple from "vue-ripple-directive";
  import {quillEditor} from 'vue-quill-editor'
  import ImageResize from 'quill-image-resize-module';
  import ToastificationContent from '../../../../@core/components/toastification/ToastificationContent'

  export default {
    name: "CategoryAdd",
    components: {
      quillEditor,
      BImg, BButton, BFormTextarea, BRow, BCol, BCard, BForm, BFormInput, BFormGroup, BFormSelect
    },
    directives: {
      Ripple
    },
    data() {
      return {
        postData: {
          page_id: "TT",
          is_research_achievements: false,
          title: '',
          summary: '',
          product_url: 'nothing',
          author: '',
          category_id: '',
          content: '',
          avatar: ''
        },
        isValidate: false,
        categories: [],
        editorOption: {
          theme: 'snow',
          modules: {
            toolbar: {
              container: [
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'align': []}],
                ['image', 'video'],
                ['clean']                                         // remove formatting button
              ],
              handlers: {
                'image': () => {
                  const input = document.createElement('input');

                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.click();

                  input.onchange = async () => {
                    console.log(this.$refs.quillEditor)
                    console.log(Object.keys(this.$refs.quillEditor))
                    console.log(this.$refs.quillEditor.quill)
                    console.log(this.$refs.quillEditor.Quill)
                    const editor = this.$refs.quillEditor.quill
                    const file = input.files[0];
                    const formData = new FormData();

                    formData.append('image', file);

                    // Save current cursor state
                    const range = editor.getSelection(true);

                    // Insert temporary loading placeholder image
                    editor.insertEmbed(range.index, 'image', `https://hub.devculi.com/loading.png`);

                    // Move cursor to right side of image (easier to continue typing)
                    editor.setSelection(range.index + 1);

                    // Post to an api endpoint which uploads to s3. It returns the s3 url
                    const res = await this.$http.post("/upload/image", formData);

                    console.log(res.data)
                    // Remove placeholder image
                    editor.deleteText(range.index, 1);

                    // Insert uploaded image
                    editor.insertEmbed(range.index, 'image', `https://hrdc.devculi.com/${res.data}`);
                  }
                  // https://forum.vuejs.org/t/how-to-upload-image-in-s3-by-using-quileditor/49452
                  // https://jsfiddle.net/jamesbrndwgn/23tnmeu5/21/
                }
              }
            },
            imageResize: {}
          }
        }
      }
    },
    computed: {
      categoryOptions() {
        return this.categories.map((e) => {
          return {
            value: e.id,
            text: e.name
          }
        })
      }
    },
    created() {
      this.$http.get('/search/category?page_id=TT&is_research_achievements=0')
          .then(res => {
            this.categories = res.data.data
          })
    },
    methods: {
      isInvalid(val) {
        return this.isValidate && this.countHtmlWord(val) === 0
      },
      onEditorBlur(quill) {
        console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
        console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
        console.log('editor ready!', quill)
        console.log(ImageResize)
        window.Quill.register('modules/imageResize', ImageResize)
      },
      onEditorChange({quill, html, text}) {
        console.log('editor change!', quill, html, text)
        this.content = html
      },
      countHtmlWord(val) {
        const regex = /(<([^>]+)>)/ig;
        let result = val.replaceAll("&nbsp;", " ");
        result = result.replace(regex, "");
        return result.length
      },
      handleSubmit () {
        this.isValidate = true
        if (
            this.postData.title.length &&
            this.postData.summary.length &&
            this.postData.product_url.length &&
            this.postData.author.length &&
            this.postData.category_id &&
            this.postData.content.length &&
            this.postData.avatar.length)
        {
          this.handleRequest()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Vui lòng nhập đầy đủ thông tin!',
              variant: 'danger',
            },
          })
        }
      },
      async handleRequest() {
        this.$http.post('/posts', this.postData)
            .then(response => {
              console.log(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Cập nhật thành công👋',
                  variant: 'success',
                },
              })
              this.$router.push('/manage/post/list')
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                  variant: 'danger',
                },
              })
            })
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/quill.scss';

  .ql-container {
    min-height: 500px;
  }
</style>
